import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchJobDetails } from "../../../../redux/services";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Modal from "../../../modal/Modal";
import { JobPageContext } from "../../../../lib/context";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");
import { convertFromHTML, ContentState, EditorState, Modifier } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import AvailabilityModal from "./AvailabilityModal";
import moment from "moment";

const initialSubject =
  "Interview Availability for {{job_name}} at {{org_name}}";

const initialTemplate = `Dear {{first_name}},
I hope this email finds you well.

We were impressed by your application for the {{job_name}}  position at {{org_name}} and would like to schedule an interview with you. Could you please provide your availability over the next few days so we can arrange a convenient time for the interview?

{{availability_link}}

We look forward to discussing your qualifications and the opportunity further.

Thank you, and we hope to hear from you soon.

Best regards,
{{org_name}}`;

function InviteModal({
  modalDismiss,
  matchprofileIds,
  isEmailSection = false,
  updateBulkAvailStatus,
}) {
  const { setContentReload } = useContext(JobPageContext);
  const [inputEmail, setInputEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [AssessmentId, setAssessmentId] = useState("");
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState({});
  const [expiryHrs, setExpiryHrs] = useState(60);
  const [expiryDate, setExpiryDate] = useState("");
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [variables, setVariables] = useState([]);
  const [Availability_subject, setAvailabilitySubject] =
    useState(initialSubject);
  const dispatch = useDispatch();
  const params = useParams();
  const [tabName, setTabName] = useState("Assessment");
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const applicant = useSelector((state) => state?.jobCandidateMatch?.profile);
  const formatText = (text) => {
    text = String(text).slice(2, -2);
    text = text.replace(/_/g, " ");
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  };
  const [Timings, setTimings] = useState({
    StartTime: "9:00 AM",
    endTime: "5:00 PM",
    StartDate: new Date(),
    EndDate: new Date(new Date().setDate(new Date().getDate() + 2)),
  });

  const [showInvite, setShowInvite] = useState("");
  useEffect(() => {
    // Set initial template content in the editor
    const contentState = Modifier.insertText(
      editorStateDesc.getCurrentContent(),
      editorStateDesc.getSelection(),
      initialTemplate,
    );
    const newEditorState = EditorState.push(
      editorStateDesc,
      contentState,
      "insert-characters",
    );
    setEditorStateDesc(newEditorState);
  }, []);

  const addText = (text) => {
    const currentContent = editorStateDesc.getCurrentContent();
    const currentSelection = editorStateDesc.getSelection();
    const newContent = Modifier.insertText(
      currentContent,
      currentSelection,
      text,
    );
    let newEditorState = EditorState.push(
      editorStateDesc,
      newContent,
      "insert-characters",
    );
    const newSelection = newEditorState.getSelection().merge({
      anchorOffset: currentSelection.getAnchorOffset() + text.length,
      focusOffset: currentSelection.getFocusOffset() + text.length,
    });
    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    setEditorStateDesc(newEditorState);
  };
  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const { jobId, jobDetails, jobPosition, OrgName } = useSelector((state) => {
    return {
      jobDetails: state?.JobExpandSlice?.assessment,
      jobId: state?.JobExpandSlice?.profile?.id,
      jobPosition: state?.JobExpandSlice?.profile?.position,
      OrgName: state?.JobExpandSlice?.profile?.company?.name,
    };
  });
  const { user } = useContext(core.UserContext);
  const [selectedEmail, setSelectedEmail] = useState(user.email ?? "");

  useEffect(() => {
    getVariables();
  }, []);

  const getVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_VARIABLE_LIST,
      1,
    );
    if (json?.data) {
      const extractedVariables = json.data.map(
        (item) => item.variable.variable,
      );
      setVariables([
        "{{job_name}}",
        "{{org_name}}",
        "{{first_name}}",
        "{{availability_link}}",
      ]);
    }
  };
  const formatDateTime = (date, time) => {
    // Combine date and time
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const formattedTime = moment(time, ["h:mm A"]).format("HH:mm:ss");
    return moment(`${formattedDate} ${formattedTime}`).toISOString();
  };
  const convertTemplateToHTML = (template) => {
    let html = template.replace(/\n/g, "<br>");
    html = `<p>${html}</p>`;

    return html;
  };
  const AvailabilityInvite = async () => {
    // const bodyContent = convertToHTML(editorStateDesc?.getCurrentContent());
    const bodyContent = convertTemplateToHTML(initialTemplate);

    const startDateTime = formatDateTime(
      Timings?.StartDate,
      Timings?.StartTime,
    );
    const endDateTime = formatDateTime(Timings?.EndDate, Timings?.endTime);
    const requestData = {
      subject: initialSubject,
      body: bodyContent,
      matchProfiles: matchprofileIds,
      job: jobId,
      org_name: OrgName ? OrgName : user.organization.name,
      job_name: jobPosition,
      interviewerAvailability: {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        timezone: moment.tz.guess(),
      },
      from: selectedEmail,
    };

    console.log(requestData);

    // https://api.scheduler.sproutsai.com/user

    setLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_AVAILABILITY_INVITE,
        1,
        requestData,
      );

      if (response.code == 201) {
        console.log("Invite sent successfully:");
        setLoading(false);
        toast.success("Invite Sent Successfully");
        updateBulkAvailStatus("availablity_status", "Pending");
        modalDismiss();
        setMessage({
          type: "success",
          message: "Invitation sent to candidate.",
        });
        // setTimeout(() => modalDismiss(), 2500);
      } else {
        console.error("Error sending invite:", response);
        toast.error("Error Sending Invite");
        setLoading(false);
        setMessage({
          type: "error",
          message: error?.message ?? "Something went wrong. Please try again",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setMessage({
        type: "error",
        message: error?.message ?? "Something went wrong. Please try again",
      });
    }
  };

  useEffect(() => {
    if (jobDetails) setAssessmentDetails(jobDetails?.assessments);
  }, [jobDetails]);

  function handleEnter(email) {
    if (/\S+@\S+\.\S+/.test(email)) {
      if (emailList.includes(email)) {
        setWarning("Email already added");
        setInputEmail("");
      } else {
        setWarning("");
        setEmailList([...emailList, email]);
        setInputEmail("");
      }
    } else {
      setWarning("Invalid format for email. Try again");
    }
  }

  const onCancel = () => {
    console.log("cancel");
    setEmailList([]);
    setInputEmail("");
    setAssessmentId("");
    setWarning("");
    setExpiryHrs(60);
    modalDismiss();
  };

  const [loading, setLoading] = useState(false);
  const inviteCandidate = async () => {
    if (
      date == new Date().toISOString().split("T")[0] &&
      time < getCurrentTime()
    ) {
      setMessage({
        type: "error",
        message: "Invalid time. Please select a time in the future",
      });
      return;
    }
    setLoading(true);
    try {
      console.log(emailList, inputEmail, jobId, AssessmentId);
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_INVITE_CANDIDATE_ASSESSMENT,
        1,
        {
          matchprofileIds: matchprofileIds,
          job: jobId,
          assessment: AssessmentId,
          expiryDate: new Date(date + "T" + time + ":00").toUTCString(),
          emails: emailList,
        },
      );
      if (json.data) {
        console.log(json.data);
        setContentReload((prev) => prev + 1);
        setLoading(false);
        toast.success(json.data.message);
        updateBulkAvailStatus("assessment_status", "Sent");
        setMessage({
          type: "success",
          message: "Invitation sent to candidate.",
        });
        setTimeout(() => modalDismiss(), 2500);
      } else {
        console.log("error", json);
        toast.error(json.error.message);
        setMessage({
          type: "error",
          message: json?.message ?? "Something went wrong. Please try again",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      setMessage({
        type: "error",
        message: error?.message ?? "Something went wrong. Please try again",
      });
    }
  };

  const modalElements = {
    heading: "Invite candidates",
    subheading: "Add candidates to invite them for assessment.",
    modalDismiss: () => {
      onCancel();
    },
    onSave: () => {
      if (tabName === "Assessment") {
        inviteCandidate();
      } else {
        AvailabilityInvite();
      }
    },
    defaultButtons: {
      primaryDisabled:
        loading ||
        (tabName === "Assessment"
          ? !(AssessmentId?.length > 0 && expiryHrs > 0 && date && time)
          : !(
              Availability_subject?.length > 0 &&
              editorStateDesc.getCurrentContent().hasText() &&
              user?.userIntegrations?.refresh_token
            )),
      primaryLabel: loading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : tabName === "Assessment" ? (
        "Save"
      ) : (
        "Invite"
      ),
    },
  };

  return (
    <Modal {...modalElements} className={"sm:!max-w-[60%] !pb-5 h-fit"}>
      <div className="bg-customColor h-[42px] w-[fit-content] rounded-[10px] py-[2px] px-[1.5px] inline-flex items-center my-[15px]">
        <button
          onClick={() => setTabName("Assessment")}
          className={`px-[18px] py-[8.5px] flex items-center justify-center ${
            tabName === "Assessment"
              ? "bg-[#FFF] rounded-[8px]"
              : "bg-transparent"
          } `}
        >
          Assessment
        </button>
        <button
          onClick={() => setTabName("Availability")}
          className={`px-[18px] py-[8.5px] flex items-center justify-center ${
            tabName === "Availability"
              ? "bg-[#FFF] rounded-[8px]"
              : "bg-transparent"
          } `}
        >
          Availability
        </button>
      </div>

      {tabName === "Assessment" ? (
        <div>
          <div className="w-full assessment">
            <h3
              className="w-full text-black text-sm font-normal"
              htmlFor="assessment-dropdown"
            >
              Assessment
            </h3>
            <select
              name="assessment-dropdown"
              required
              disabled={!(assessmentDetails && assessmentDetails?.length > 0)}
              className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] disabled:pointer-events-none"
              onChange={(e) => setAssessmentId(e.target.value)}
            >
              {assessmentDetails ? (
                assessmentDetails?.map((item) => (
                  <>
                    <option value="" hidden>
                      Select assessment
                    </option>
                    <option key={item?._id} value={item?._id}>
                      {item?.name}
                    </option>
                  </>
                ))
              ) : (
                <option value="" hidden>
                  No assessment available
                </option>
              )}
            </select>
          </div>

          {isEmailSection && (
            <div className="w-full mt-[20px]">
              <h3
                className="w-full text-black text-sm font-normal"
                htmlFor="assessment-dropdown"
              >
                Email
              </h3>
              <input
                type="email"
                className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
                required
                value={inputEmail}
                placeholder="Press enter to insert email"
                onChange={(e) => {
                  setWarning("");
                  setInputEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                    handleEnter(e.target.value);
                  }
                }}
              />
              {warning && <span className="col-xs-12 warning">{warning}</span>}
              {emailList?.length > 0 && (
                <div className="w-full mt-3 flex flex-wrap">
                  {emailList.map((item) => (
                    <span
                      className="inline-flex rounded-lg px-2 py-1 bg-blue-200 gap-2 items-center mr-2 mb-2"
                      key={item}
                    >
                      <span className="text-sm text-gray-800 font-normal !normal-case first-letter:!normal-case">
                        {item}
                      </span>
                      <button
                        className="inline-block cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setEmailList(emailList.filter((d) => d != item));
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M24 24L8 8M24 8L8 24"
                            stroke="#001B4B"
                            strokeWidth="2.8"
                            strokeLinecap="round"
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="w-full relative mt-[20px]">
            <h3 className="text-black text-sm font-normal">Expiry</h3>
            <div className="grid grid-cols-2">
              <input
                type="date"
                className=" col-span-1 px-4 py-3  border border-gray-400  text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]"
                name="from"
                min={new Date().toISOString().split("T")[0]}
                placeholder={"Select"}
                onChange={(e) => {
                  setDate(e.target.value);
                  setMessage({});
                }}
                // value={defaultValue}
              />
              <input
                type="time"
                placeholder="Select time"
                className=" px-4 py-3 col-span-1 ml-[20px] border border-gray-400  text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]"
                onChange={(e) => {
                  setTime(e.target.value);
                  setMessage({});
                }}
                style={{
                  appearance: "none", // Remove default arrow icon
                }}
                // min={new Date().toLocaleTimeString("en-US", { hour12: false })}
              />
            </div>
            {/* <h3 className="absolute top-[36px] right-2 text-black text-sm font-normal">
          hrs
        </h3> */}
          </div>
          {Object.entries(message)?.length > 0 && (
            <span
              className={
                message.type == "success"
                  ? "text-green-800 font-normal text-sm"
                  : "text-red-800 font-normal text-sm"
              }
            >
              {message.message}
            </span>
          )}
        </div>
      ) : (
        <>
          {/* <div className="  mt-[2px]">
            <label
              className="  text-[14px] font-normal not-italic required"
              htmlFor=""
            >
              Subject
            </label>
            <input
              className="border  border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px] w-full"
              type="text"
              required={true}
              value={Availability_subject}
              onChange={(e) => setAvailabilitySubject(e?.target?.value)}
            />
          </div>

          <div className="mt-[20px]" id="description">
            <label
              className=" text-right text-[14px] mt-[5px] font-normal not-italic"
              htmlFor=""
            >
              Body
            </label>
            <Editor
              editorState={editorStateDesc}
              onEditorStateChange={setEditorStateDesc}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
            <div className="w-[calc(100%-2px)] flex flex-row flex-wrap rounded-b-lg mb-2px ml-px mt-px z-50 flex h-auto bg-[#f7f7fb] py-1 px-2 h-9 items-center gap-2">
              <h4>Add variables :</h4>
              {variables?.map((item, index) => (
                <button
                  key={"var" + (index + 1)}
                  onClick={() => addText(item)}
                  className="btn-sm btn-sec text-sm"
                >
                  {formatText(item)}
                </button>
              ))}
            </div>
          </div> */}
          <div className="p-4 my-2">
            <p className="text-base">From : {selectedEmail} </p>
            {!user?.userIntegrations?.refresh_token &&
              user?.email == selectedEmail && (
                <span className="text-red-500 text-xs font-normal">
                  {`( Your email is not verified. Please verify your email before
                    sending the email.)`}
                </span>
              )}
            <div className="text-base flex items-center justify-between my-2">
              <p> {initialSubject}</p>
              <button
                onClick={() => {
                  setShowInvite("InviteCandidate");
                }}
                className="px-3 py-1 bg-gray-400 text-gray-800 rounded-lg hover:bg-gray-500 hover:text-gray-900"
              >
                Edit
              </button>
            </div>
            <p>
              <pre className="whitespace-pre-wrap text-base ">
                {initialTemplate}
              </pre>
            </p>
            <p>
              <pre className="whitespace-pre-wrap text-base my-2">
                Note: Inteviewer Availability <br></br>
                Days : From {moment(Timings?.StartDate).format(
                  "DD-MMM-YYYY",
                )}{" "}
                to {moment(Timings?.EndDate).format("DD-MMM-YYYY")}
                <br></br>
                Timings : {Timings?.StartTime} to {Timings?.endTime}
              </pre>
            </p>
          </div>

          {showInvite == "InviteCandidate" && (
            <>
              <AvailabilityModal
                modalDismiss={() => {
                  setShowInvite("");
                }}
                matchprofileIds={matchprofileIds}
                Timings={Timings}
                setTimings={setTimings}
                InterviewertimeZone={moment.tz.guess()}
                selectedEmail={selectedEmail}
                setSelectedEmail={setSelectedEmail}
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
}

export default InviteModal;
