import React from "react";
import Modal from "../../components/modal/Modal";
import { Users, Mail } from "lucide-react";

function ContactSalesModal({ candidateDetail, modalDismiss }) {
  return (
    <Modal
      heading={"Unlock Candidate Profiles"}
      modalDismiss={modalDismiss}
      noBtns={true}
    >
      <p className="text-gray-600 mb-6">
        Contact our sales team to unlock access to our extensive database of
        pre-screened candidates.
      </p>

      <a
        href="mailto:sales@sproutsai.com"
        className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 mb-6"
      >
        <Mail size={20} className="mr-2" />
        apoorva@sproutsai.com
      </a>

      <ul className="space-y-3">
        <li className="flex items-center text-gray-700">
          <Users size={20} className="mr-2 text-green-500" />
          Access to 50,000+ vetted profiles
        </li>
        <li className="flex items-center text-gray-700">
          <Users size={20} className="mr-2 text-green-500" />
          Customized candidate matching
        </li>
      </ul>
    </Modal>
  );
}

export default ContactSalesModal;
