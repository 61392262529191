import { convertToHTML } from "draft-convert";
import { ContentState, EditorState, Modifier, convertFromHTML } from "draft-js";
import React, { useEffect, useState } from "react";
import InputBox from "../../../layouts/InputBox";
import { Editor } from "react-draft-wysiwyg";
import { getCharacterCountWithoutHTML } from "./getCharCount";
import TemplateVaribleModal from "../sequencing/TemplateVaribleModal";
import { ICONS } from "../../assets/componentAssets";
import { Tooltip } from "antd";
import { ImAttachment } from "react-icons/im";
import { useRef } from "react";
import Attachments from "../../../layouts/Attachments";
import { RichUtils } from "draft-js";
import { handleNewLine, insertNewUnstyledBlock } from "draftjs-utils";

const core = require("../../../lib/core");

const TemplateForm = ({
  formData,
  setData,
  bodyCharCount,
  setAttachments,
  attachments,
  attachmentsUrl,
  removeAttachmentUrl,
}) => {
  const [body, setBody] = useState(() =>
    formData?.body
      ? EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(formData?.body?.split("&lt;").join("<")).toString(),
            ),
          ),
        )
      : EditorState.createEmpty(),
  );
  const [variables, setVariables] = useState([]);

  const [showVaribleModal, setShowVaribleModal] = useState(false);

  useEffect(() => {
    getVariables();
  }, []);

  useEffect(() => {
    setData("body", convertToHTML(body?.getCurrentContent()));
    bodyCharCount(getCharacterCountWithoutHTML(body));
  }, [body]);

  const customBlockRenderFunc = (block) => {
    console.log(block);
    if (block.getType() === "paragraph") {
      return {
        element: "p",
        style: "margin-bottom:0px;",
      };
    }
  };

  const getVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_VARIABLE_LIST,
      1,
    );
    if (json?.data) setVariables(json?.data || []);
  };

  const formatText = (text) => {
    text = text.slice(2, -2);
    text = text.replace(/_/g, " ");
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  };

  const addText = (text) => {
    const currentContent = body.getCurrentContent();
    const currentSelection = body.getSelection();
    const newContent = Modifier.insertText(
      currentContent,
      currentSelection,
      text,
    );

    let newEditorState = EditorState.push(
      body,
      newContent,
      "insert-characters",
    );
    const newSelection = newEditorState.getSelection().merge({
      anchorOffset: currentSelection.getAnchorOffset() + text.length,
      focusOffset: currentSelection.getFocusOffset() + text.length,
    });
    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    setBody(newEditorState);
  };

  const fileInputRef = useRef(null);

  const handleAttachment = (event) => {
    const files = Array.from(event.target.files);
    setAttachments([...attachments, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };
  return (
    <>
      {showVaribleModal ? (
        <TemplateVaribleModal
          variables={variables}
          showModal={showVaribleModal}
          setShowModal={setShowVaribleModal}
          getVariables={getVariables}
        />
      ) : null}

      <div className="w-full flex gap-4">
        <div className="w-full">
          <label className="text-sm font-normal not-italic required" htmlFor="">
            Template name
          </label>
          <InputBox
            size={"medium"}
            placeholder={"Enter template name"}
            value={formData?.name}
            onChange={(e) => setData("name", e?.target?.value)}
          />
        </div>
        <div className="w-full">
          <label className="text-sm font-normal not-italic required" htmlFor="">
            Template type
          </label>
          <InputBox
            size={"medium"}
            placeholder={"Enter template type"}
            value={formData?.type}
            onChange={(e) => setData("type", e?.target?.value)}
          />
        </div>
      </div>
      <div className="w-full">
        <label className="text-sm font-normal not-italic required" htmlFor="">
          Subject
        </label>
        <InputBox
          size={"medium"}
          placeholder={"Enter email subject"}
          value={formData?.subject}
          onChange={(e) => setData("subject", e?.target?.value)}
        />
      </div>

      <div className="w-full relative">
        <label className="text-right text-sm font-normal not-italic required">
          Body
        </label>
        <Editor
          editorState={body}
          onEditorStateChange={setBody}
          stripPastedStyles={true}
          wrapperClassName={"wrapper-class !w-[calc(100%-2px)]"}
          editorClassName="editor-class !min-h-[75px] !max-h-[800px] pb-12 rounded-b-lg pr-5"
          toolbarClassName="toolbar-class"
          handleReturn={(event) => {
            // override behavior for enter key
            var newEditorState = null;
            if (event.keyCode === 13 && event.shiftKey) {
              // with shift, make a new block
              newEditorState = insertNewUnstyledBlock(body);
            } else if (event.keyCode === 13 && !event.shiftKey) {
              // without shift, just a normal line break
              newEditorState = RichUtils.insertSoftNewline(body);
            }
            if (newEditorState) {
              setBody(newEditorState);
              return true;
            }
            return false;
          }}
          toolbar={{
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "superscript",
                "subscript",
              ],
            },
          }}
        />
        <div className="w-[calc(100%-2px)] rounded-b-lg mb-px ml-px flex flex-wrap absolute bottom-0 bg-[#f7f7fb] py-1 px-2 h-9 items-center gap-2 z-50 h-fit">
          <h4>Add variables :</h4>
          {/*<button
            onClick={() => addText("{{AI_CONTENT}}")}
            className="btn-sm btn-sec flex items-center gap-2"
          >
            <ICONS.Stars />
            <p className="bg-gradient-to-r from-[#5581EC] to-[#D46779] text-transparent bg-clip-text">
              AI Content
            </p>
          </button>*/}
          {variables?.map((item, index) => (
            <button
              key={index}
              onClick={() => addText(item.variable.variable)}
              className="btn-sm btn-sec flex items-center gap-2"
            >
              <Tooltip
                color="white"
                title={
                  <div className="bg-white p-2 rounded-lg  text-black">
                    {item.variable?.tooltip || item.variable.label}
                  </div>
                }
              >
                <p className="mb-0">{item.variable.label}</p>
              </Tooltip>
              <Tooltip
                color="white"
                title={
                  <div className="bg-white p-2 rounded-lg  text-black">
                    Copy
                  </div>
                }
              >
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigator.clipboard.writeText(item.variable.variable);
                  }}
                  className="hover:text-[#D46779] cursor-pointer"
                >
                  <ICONS.Copy width={15} height={15} />
                </span>
              </Tooltip>
            </button>
          ))}
          <button
            onClick={() => {
              setShowVaribleModal(true);
            }}
            className="btn-sm btn-sec"
          >
            +
          </button>
        </div>
      </div>

      <Attachments
        attachments={attachments}
        attachmentsUrl={attachmentsUrl}
        removeAttachment={removeAttachment}
        removeAttachmentUrl={removeAttachmentUrl}
        handleAttachment={handleAttachment}
      />
    </>
  );
};

export default TemplateForm;
