import React, { useState, useRef, useEffect } from "react";

const TruncateText = ({ text, maxLines = 3, className = "" }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const lineHeight = parseInt(
          window.getComputedStyle(textRef.current).lineHeight,
        );
        const maxHeight = lineHeight * maxLines;
        setShowButton(textRef.current.scrollHeight > maxHeight);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [text, maxLines]);

  return (
    <div className={className}>
      <p
        ref={textRef}
        className={`${isTruncated ? "line-clamp-3" : ""}`}
        style={{ overflow: "hidden" }}
      >
        {text}
      </p>
      {showButton && (
        <button
          onClick={() => setIsTruncated(!isTruncated)}
          className="text-blue-600 hover:text-blue-800 mt-2"
        >
          {isTruncated ? "See more" : "See less"}
        </button>
      )}
    </div>
  );
};

export default TruncateText;
