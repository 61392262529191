import React, { useContext, useEffect, useState } from "react";
import {
  GoogleMeet,
  ZoomIcon,
  GoogleCalendar,
  OutlookCalendar,
} from "../../../../components/recruiter/candidate/icons/Icons";
import Google from "../../../../components/assets/recruiter/google.png";
import Microsoft from "../../../../components/assets/recruiter/microsoft.png";
import AuthCard from "./AuthCard";
import { RxCross1 } from "react-icons/rx";
import { GoQuestion, GoStar } from "react-icons/go";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
const core = require("../../../../lib/core");

const Meetingauthorization = () => {
  const [gmeetAuthStatus, setGmeetAuthStatus] = useState(false);
  const [userIntegrations, setUserIntegrations] = useState([]);
  const [GoogleCalenderStatus, setGoogleCalenderStatus] = useState(false);
  const [OutlookCalendarStatus, setOutlookCalendarStatus] = useState(false);
  const [Service, setService] = useState("");
  const [zoomStatus, setZoomStatus] = useState(false);
  const { user } = useContext(core.UserContext);
  const meetAuthStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-meet`,
        1,
        {},
      );
      console.log("google", json);
      setGmeetAuthStatus(json?.data?.status);
      if (json?.data?.status) setService("google");
    } catch (e) {
      console.log(e);
    }
  };
  const getZoomstatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS + `${user.email}&service=zoom`,
        1,
        {},
      );
      setZoomStatus(json?.data?.status);
      if (json?.data?.status) setService("zoom");
    } catch (e) {
      console.log(e);
    }
  };
  const getGooglecalenderStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-calendar`,
        1,
        {},
      );
      if (json?.data) {
        setGoogleCalenderStatus(json.data.status);
        if (json.data.status) setService("google");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getOutlookCalenderStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_OUTLOOK_AUTH_STATUS +
          `${user.email}&service=microsoft`,
        1,
        {},
      );
      if (json?.data) {
        setOutlookCalendarStatus(json.data.status);
        if (json.data.status) setService("outlook");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getUserIntegrations = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_INTEGRATIONS,
        1,
        {},
      );
      if (json?.data) {
        setUserIntegrations(json.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    meetAuthStatus();
    getZoomstatus();
    getGooglecalenderStatus();
    getOutlookCalenderStatus();
    getUserIntegrations();
  }, []);

  const MeetingPlatforms = [
    {
      Logo: <GoogleMeet width="50" height="50" />,
      Name: "Google Meet",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_GOOGLE_MEET}${user?.email}`,
      disabled: Service === "outlook" && !gmeetAuthStatus,
      authorized: gmeetAuthStatus,
    },
    {
      Logo: <ZoomIcon width="45" height="45" />,
      Name: "Zoom",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_ZOOM_MEET}${user?.email}`,
      disabled: false,
      authorized: zoomStatus,
    },
  ];

  const CalenderPlatforms = [
    {
      Logo: <GoogleCalendar width="50" height="50" />,
      Name: "Google Calender",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_GOOGLE_CALENDAR}${user?.email}`,
      disabled: Service === "outlook" && !GoogleCalenderStatus,
      authorized: GoogleCalenderStatus,
    },
    {
      Logo: <OutlookCalendar width="50" height="50" />,
      Name: "Outlook Calender",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_OUTLOOK_CALENDAR}${user?.email}`,
      disabled: Service === "google" && !OutlookCalendarStatus,
      authorized: OutlookCalendarStatus,
    },
  ];

  const EmailPlatforms = [
    {
      Logo: <img src={Google} alt="google" className="w-12 h-12" />,
      Name: "Gmail",
      authType: 2,
      email_auth_type: "gmail",
      Link: core.GOOGLE_API.GET_EMAIL_AUTHENTICATION,
      disabled:
        user?.userIntegrations?.refresh_token &&
        user?.userIntegrations?.email_auth_type == "outlook"
          ? true
          : false,
      authorized: user?.userIntegrations?.refresh_token ? true : false,
    },
    {
      Logo: <img src={Microsoft} alt="google" className="w-12 h-12" />,
      Name: "Outlook",
      authType: 2,
      email_auth_type: "outlook",
      Link: core.OUTLOOK_API.POST_OUTLOOK_AUTH,
      disabled:
        user?.userIntegrations?.refresh_token &&
        user?.userIntegrations?.email_auth_type != "outlook"
          ? true
          : false,
      authorized: user?.userIntegrations?.refresh_token ? true : false,
    },
  ];

  const handleAuthorize = async (link, type) => {
    localStorage.setItem("email_auth_type", type);
    const json = await core.API(
      core.API_METHODS.POST,
      link + "?redirect_uri=" + window.location.href,
      1,
      {},
    );
    if (json?.url) window.open(json?.url, "_blank");
  };

  const handleRemove = async (id) => {
    const json = await core.API(
      core.API_METHODS.DELETE,
      core.USER_API.GET_INTEGRATIONS + "/" + id,
      1,
      {},
    );
    if (json?.code === 200) {
      getUserIntegrations();
    }
  };

  const handleTogglePrimary = async (id) => {
    const json = await core.API(
      core.API_METHODS.PUT,
      core.USER_API.GET_INTEGRATIONS + "/" + id,
      1,
      {
        is_primary: true,
      },
    );
    if (json?.code === 200) {
      getUserIntegrations();
    }
  };

  return (
    <>
      <ReactTooltip
        id="my-tooltip-4"
        noArrow={true}
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "10px",
          padding: "4px 8px",
          fontWeight: "400",
        }}
        place="top"
      />
      <div className="bg-white shadow-md rounded-lg py-4 px-4 my-2 mx-auto">
        <h3 className="py-2 text-lg font-medium text-gray-800">Meetings</h3>

        <div className="flex felx-row gap-8 py-2 flex-wrap">
          {MeetingPlatforms.map((item, index) => {
            return (
              <>
                <AuthCard
                  Logo={item.Logo}
                  Name={item.Name}
                  Link={item.Link}
                  disabled={item.disabled}
                  authorized={item.authorized}
                  authType={item.authType}
                />
              </>
            );
          })}
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg py-4 px-4 my-2 mx-auto">
        <h3 className="py-2 text-lg font-medium text-gray-800">Calenders</h3>

        <div className="flex felx-row gap-8 py-2 flex-wrap">
          {CalenderPlatforms.map((item, index) => {
            return (
              <>
                <AuthCard
                  Logo={item.Logo}
                  Name={item.Name}
                  Link={item.Link}
                  disabled={item.disabled}
                  authorized={item.authorized}
                  authType={item.authType}
                />
              </>
            );
          })}
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg py-4 px-4 my-2 mx-auto">
        <h3 className="py-2 text-lg font-medium text-gray-800">Mail</h3>

        {userIntegrations && userIntegrations?.length ? (
          <div className="mb-5">
            <p>Emails Verified to this account</p>
            <ul className="flex flex-col gap-2 mt-2">
              {userIntegrations &&
                userIntegrations?.length &&
                userIntegrations?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="flex justify-between items-center p-2 rounded-md bg-gray-300"
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={
                            item?.email_auth_type == "outlook"
                              ? Microsoft
                              : Google
                          }
                          alt="logo"
                          className="w-6 h-6"
                        />
                        <span className="flex items-center gap-2">
                          {item.email}
                          {item.is_primary ? (
                            <span className="text-green-800 ml-10 flex items-center gap-2">
                              Primary
                              <span
                                data-tooltip-id="my-tooltip-4"
                                data-tooltip-content={
                                  "This email is used for default email services"
                                }
                              >
                                <GoQuestion />
                              </span>
                            </span>
                          ) : null}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        {item.is_primary ? (
                          <div className="h-8 w-8"></div>
                        ) : (
                          <button
                            data-tooltip-id="my-tooltip-4"
                            onClick={() => {
                              handleTogglePrimary(item?._id);
                            }}
                            data-tooltip-content={"Make this primary"}
                            className="h-8 w-8 rounded-lg flex justify-center items-center border border-blue-800"
                          >
                            <GoStar className="text-blue-800" />
                          </button>
                        )}
                        <button
                          data-tooltip-id="my-tooltip-4"
                          onClick={() => {
                            handleRemove(item?._id);
                          }}
                          data-tooltip-content={"Remove"}
                          className="h-8 w-8 rounded-lg flex justify-center items-center border border-red-800"
                        >
                          <RxCross1 className="text-red-800" />
                        </button>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : null}
        <div className="flex gap-2 py-2 flex-wrap">
          {EmailPlatforms.map((item, index) => {
            // return (
            //   <>
            //     <AuthCard
            //       Logo={item.Logo}
            //       Name={item.Name}
            //       authType={item.authType}
            //       Link={item.Link}
            //       disabled={item.disabled}
            //       authorized={item.authorized}
            //       email_auth_type={item.email_auth_type}
            //     />
            //   </>
            // );
            return (
              <div
                className="flex gap-3 items-center | p-2 px-4 | border-2 border-gray-300 rounded-md | cursor-pointer | hover:shadow-md"
                onClick={() =>
                  handleAuthorize(item?.Link, item?.email_auth_type)
                }
              >
                <img
                  src={item?.email_auth_type == "outlook" ? Microsoft : Google}
                  alt="logo"
                  className="w-6 h-6 "
                />
                Connect New Account
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Meetingauthorization;
